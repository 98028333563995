import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import MainSection from 'bundles/App/pages/ProductListingPage/MainSection/MainSection';
import Meta from 'styleguide/components/Meta/Meta';

const Success = (): JSX.Element => (
  <>
    <Meta
      title="Request Successfully Submitted! | Printivty"
      canonical="https://www.printivity.com/success"
    />
    <TitleContainer title="Success" />
    <div className="mx-auto max-w-base px-1">
      <div className="mb-4 mt-8 font-hvBold text-xl font-bold">
        Your request has been successfully sent! You will receive a reply shortly.
      </div>
      <div className="mb-2">In the meantime, please browse our catalog!</div>
      <MainSection />
    </div>
    <Footer />
  </>
);

export default Success;
